import { configureStore } from '@reduxjs/toolkit'
import profileImage from './profileImage'
import isLoggedIn from './isLoggedIn'
import myJobs from "./myJobs"

export default configureStore({
  reducer: {
    myJobs: myJobs,
    profileImage: profileImage,
    isLoggedIn: isLoggedIn
  }
})
