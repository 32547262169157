import * as React from 'react'
import { Accordion, Button, Box } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ViewApplicantsDialog from '../ViewApplicantsDialog/ViewApplicantsDialog'
export default function SimpleAccordion({ job }) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography variant='h6'>{job.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='subtitle1'>{job.description}</Typography>
          <Box sx={{ justifyContent: 'flex-end' }}>
            <ViewApplicantsDialog
              job={job}
              applicants={job.applicants}
              buttonName={'view applicants'}
            ></ViewApplicantsDialog>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
