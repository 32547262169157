import React, { useState, useEffect } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import TextField from '@mui/material/TextField'
import './autoComplete.css'
function AutoComplete(props) {
  const [address, setAddress] = useState('')

  useEffect(() => {
    setAddress(props.address)
  }, [props.address])

  useEffect(() => {
    if (props.value) getProps()
    if (props.submitted?.status) setAddress('')
  }, [props.value, props?.submitted])

  const getProps = () => {
    setAddress(props.value.address)
    props.handleChange('userAddress', {
      address: props.value.address,
      coordinates: {
        lat: props.value.coordinates.lat,
        lng: props.value.coordinates.lng
      }
    })
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    const ll = await getLatLng(results[0])
    setAddress(value)
    // setCoordinates(ll)
    props.handleChange('userAddress', {
      address: results[0].formatted_address,
      coordinates: ll
    })
  }

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              id='outlined-basic'
              label='Address'
              variant='outlined'
              style={{ width: '100%' }}
              {...getInputProps({
                autoComplete: 'off'
              })}
            />

            <div className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      cursor: 'pointer'
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer'
                    }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

export default AutoComplete
