import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import DateTimePicker from '@mui/lab/DateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import TextField from '@mui/material/TextField'

function BasicDateTimePicker(props) {
  //   const [value, setValue] = useState({
  //     startDate: new Date(),
  //     endDate: new Date()
  //   })

  //   const handleChange = (key, newValue) => {
  //     setValue({ ...value, [key]: newValue })
  //   }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={1}>
        <DateTimePicker
          label='Start Date & Time'
          value={props.job.startDate}
          onChange={(value) => props.handleChangeAddress('startDate', value)}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          label='End Date & Time'
          value={props.job.endDate}
          onChange={(value) => props.handleChangeAddress('endDate', value)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  )
}

export default BasicDateTimePicker
