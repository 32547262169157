import * as React from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'
import Box from '@mui/material/Box'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask='(#00) 000-0000'
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default function FormattedInputs(props) {
  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000'
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
    props.handleChange('phone', event.target.value)
  }

  return (
    <Box>
      <FormControl variant='standard'>
        <InputLabel htmlFor='formatted-text-mask-input'>
          Phone Number
        </InputLabel>
        <Input
          value={values.textmask}
          onChange={handleChange}
          name='textmask'
          id='formatted-text-mask-input'
          inputComponent={TextMaskCustom}
        />
      </FormControl>
    </Box>
  )
}
