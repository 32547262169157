import { auth } from "../firebase/firebase-config";
import { onAuthStateChanged, signOut } from "firebase/auth";

export const userAuthStateChanged = (setUser) => {
  onAuthStateChanged(auth, async (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
      const token = await currentUser.getIdToken();
      console.log(token);
    } else {
      console.log("no user found");
    }
  });
};

export const logOut = async () => {
  await signOut(auth);
};
