import React, { useEffect, useState } from "react";
import { getProfileImage } from "../../helper/getProfileImage";
import { Badge, Avatar } from "@mui/material";
import ImageUploadButton from "../ImageUploadButton/ImageUploadButton";
import { useSelector } from "react-redux";
import SkeletonProfile from "../Skeleton/SkeletonProfile";

function ProfileImage({ params }) {
  const userId = localStorage.getItem("user");
  const [image, setImage] = useState();
  const { profileImage } = useSelector((state) => state.profileImage);

  const showUploadButton = () => {
    // shows image upload button if user is on his own profile
    if (params.userId === userId) {
      return <ImageUploadButton />;
    }
  };

  useEffect(() => {
    updateProfileImage();

    return setImage();
  }, [profileImage, params]);

  const updateProfileImage = async () => {
    const imageUrl = await getProfileImage(params.userId);
    setImage(imageUrl);
  };

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={showUploadButton()}
    >
      {!image ? (
        <SkeletonProfile height={125} width={125} />
      ) : (
        <Avatar sx={{ height: 125, width: 125 }} alt="Avatar" src={image} />
      )}
    </Badge>
  );
}

export default ProfileImage;
