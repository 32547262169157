import { useState, useEffect } from "react";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import { createUser } from "../../firebase/firebase-users";
import { updateUser } from "../../firebase/firebase-users";
import { createNotifications } from "../../firebase/firebase-notifications";

import TextField from "@mui/material/TextField";
import { signOut } from "firebase/auth";

import "./login.css";

function Login() {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    userAddress: {
      address: "",
      coordinates: {},
    },
    isLoggedIn: true,
    rating: { stars: 0, total: 0 },
  });

  const [loading, setLoading] = useState({ register: false, signIn: false });
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({ register: "", login: "" });

  // if localStorage does not have a userId then log user out.
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) signOut(auth);
  });

  const registerUserToDB = async (id) => {
    await createUser(id, userInfo);
    await createNotifications(id, { userId: id });
    updateUser({ isLoggedIn: true }, id);
  };

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      registerUserToDB(user.user.uid);
      setErrorMessage({ register: "" });
    } catch (error) {
      setLoading(false);
      setErrorMessage({ register: error.message });
    }
  };

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      setErrorMessage({ login: "" });
      // update db user logged in
      updateUser({ isLoggedIn: true }, user.user.uid);
    } catch (error) {
      setLoading(false);
      setErrorMessage({ login: error.message });
    }
  };

  const handleChange = (formKey, value) => {
    setUserInfo({
      ...userInfo,
      [formKey]: value,
    });
  };

  return (
    <div className="login-page-container">
      <div className="logo-container">
        <h1 className="logo-primary">Work when you want,</h1>
        <h1 className="logo-primary"> Where you want.</h1>
        <h1 className="logo">Lets get started.</h1>
      </div>
      <div className="login-container">
        <div className="grid-container">
          <h3 className="label login-label">Login</h3>
          <p className="error-login">{errorMessage.register}</p>

          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            className="login-input-email"
            label="email"
            onChange={(e) => {
              setLoginEmail(e.target.value);
            }}
          />
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            className="login-input-password"
            label="password"
            type="password"
            onChange={(e) => {
              setLoginPassword(e.target.value);
            }}
          />

          <button
            className="login-btn"
            onClick={() => {
              setLoading({ signIn: true });
              login();
            }}
          >
            {loading.signIn ? "Loggin in..." : "Login"}
          </button>
          <p className="no-account">Don't have an account?</p>
          <h3 className="label register-label">Register User</h3>
          <p className="error-register">{errorMessage.login}</p>
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            className="fname"
            label="first name"
            required
            onChange={(e) => {
              handleChange("firstName", e.target.value);
            }}
          />
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            className="lname"
            label="last name"
            required
            onChange={(e) => {
              handleChange("lastName", e.target.value);
            }}
          />
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            className="register-email"
            label="email"
            required
            onChange={(e) => {
              setRegisterEmail(e.target.value);
            }}
          />
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            className="register-password"
            label="password"
            type="password"
            required
            onChange={(e) => {
              setRegisterPassword(e.target.value);
            }}
          />

          <button
            className="register-btn"
            onClick={() => {
              setLoading({ register: true });
              register();
            }}
          >
            {loading.register ? "Creating user..." : "Register"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
