import React, { useState, useEffect, useLayoutEffect } from "react";
import CardJobs from "../../components/Card/CardJobs";
import ProgressBar from "../../components/Progressbar/ProgressBar";
import NotFound from "../../components/NotFound/NotFound";
import CardJobsPreview from "../../components/Card/CardJobsPreview";
import { db } from "../../firebase/firebase-config";
import { query, collection, getDocs, orderBy } from "firebase/firestore";
import "./listings.scss";
import { updateJobApplicants, getJob } from "../../firebase/firebase-jobs";
import { getUser, updateUserJobs } from "../../firebase/firebase-users";
import { updateNotification } from "../../firebase/firebase-notifications";
import { Typography } from "@mui/material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Listings() {
  const [open, setOpen] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [cardJobOptions, setCardJobOptions] = useState();

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const notFoundMessageOptions = {
    header: "No jobs found...",
    description: "Click here to create one",
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    setSelectedJob({ ...jobs[0] });
  }, [jobs]);

  useEffect(() => {
    if (windowDimensions.width >= 900) {
      setCardJobOptions({ pointer: true, height: "250px", mobileView: false });
    } else {
      setCardJobOptions({
        ...cardJobOptions,
        pointer: true,
        height: "400px",
        mobileView: true,
      });
    }
  }, [windowDimensions]);

  const getJobs = async () => {
    const jobsCollectionRefQuery = query(
      collection(db, "jobs"),
      orderBy("createdAt", "desc")
    );

    const data = await getDocs(jobsCollectionRefQuery);
    setJobs(data.docs.map((doc) => ({ ...doc.data(), jobId: doc.id })));

    setOpen(false);
  };

  const applyJob = async (user, job, status, type) => {
    const currentUser = await getUser(user);
    const userData = currentUser.data();

    await updateJobApplicants(currentUser.id, userData, job.jobId, status);
    await updateUserJobs(job.jobId, currentUser.id, job, type);
    await updateNotification(currentUser.id, job, userData, type);
    getSelectedJob(job);
  };

  const getSelectedJob = async (job) => {
    const data = await getJob(job.jobId);

    setSelectedJob({ ...data.data(), jobId: data.id });
  };

  return (
    <div className="listings-container-main">
      <div className="listing-form-container">
        <ProgressBar open={open} />
        {!jobs.length && !open && (
          <div className="listings-not-found-container">
            <NotFound message={notFoundMessageOptions} />
          </div>
        )}
        {jobs.length > 0 && !open && (
          <>
            <div className="listings-title-container">
              <Typography variant="h4" className="listings-title">
                Available Jobs
              </Typography>
            </div>
            <div className="listings-container">
              <div
                className={
                  windowDimensions.width >= 900
                    ? "listings-container-card-jobs"
                    : "listing-container-card-jobs-mobile"
                }
              >
                {jobs.map((job, i) => (
                  <div
                    key={i}
                    className={`listings-card ${
                      selectedJob.jobId === job.jobId && "selected"
                    }`}
                    onClick={() => getSelectedJob(job)}
                  >
                    <CardJobs
                      jobs={job}
                      options={cardJobOptions}
                      applyJob={applyJob}
                      selectedJob={selectedJob}
                    />
                  </div>
                ))}
              </div>
              {windowDimensions.width >= 900 && (
                <div>
                  {selectedJob.userId && (
                    <CardJobsPreview
                      selectedJob={selectedJob}
                      applyJob={applyJob}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Listings;
