import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase-config";
import { doc, onSnapshot } from "firebase/firestore";
import { Card } from "@mui/material";
import NotificationItem from "../NotificationItem/NotificationItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function NotificationWindows({ setNotify, handleClickAwayNotification }) {
  const [notSeenNotification, setNotSeenNotification] = useState();
  const [seenNotification, setSeenNotification] = useState();

  const user = localStorage.getItem("user");

  useEffect(() => {
    initNotificationListener();
  }, []);

  const initNotificationListener = () => {
    const notificationRef = doc(db, "notifications", user);

    const unsub = onSnapshot(notificationRef, (doc) => {
      const notificationNotSeen = doc.data().notificationNotSeen;
      setNotSeenNotification(notificationNotSeen);
      const notificationSeen = doc.data().notificationSeen;

      setSeenNotification(notificationSeen);
      if (!notificationNotSeen) return;
      const length = notificationNotSeen.length;
      setNotify(length);
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayNotification}>
      <Card className="nav-notifications">
        <NotificationItem
          notSeenNotification={notSeenNotification}
          seenNotification={seenNotification}
        />
      </Card>
    </ClickAwayListener>
  );
}

export default NotificationWindows;
