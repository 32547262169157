import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./copyright.scss";

function Copyright() {
  return (
    <div className="copyright-container">
      <p>
        Copyright
        <span>
          <CopyrightIcon fontSize=".2rem" />
        </span>
        All Rights Reserved
      </p>
    </div>
  );
}

export default Copyright;
