import { db } from './firebase-config'
import {
  collection,
  updateDoc,
  doc,
  setDoc,
  getDoc,
  arrayUnion,
  deleteField
} from 'firebase/firestore'

const notificationsCollectionRef = collection(db, 'notifications')

export const createNotifications = async (id, value) => {
  await setDoc(doc(db, 'notifications', id), value)
}

export const getNotification = async (id) => {
  try {
    const docRef = doc(db, 'notifications', id)
    return await getDoc(docRef)
  } catch (error) {
    console.log(error)
  }
}

export const updateNotification = async (userId, job, userData, type) => {
  console.log(job)
  const notificationDoc = doc(db, 'notifications', job.userId)
  await updateDoc(notificationDoc, {
    notificationNotSeen: arrayUnion({
      applicantId: userId,
      jobId: job.jobId,
      jobTitle: job.title,
      applicantFirstName: userData.firstName,
      applicantLastName: userData.lastName,
      type,
      seen: false
    })
  }).catch((error) => console.log(error))
}

export const updateNotificationToSeen = async (
  userId,
  applicantFirstName,
  applicantId,
  applicantLastName,
  jobId,
  jobTitle,
  type
) => {
  const notificationDoc = doc(db, 'notifications', userId)
  await updateDoc(notificationDoc, {
    notificationSeen: arrayUnion({
      applicantId,
      jobId,
      jobTitle,
      applicantFirstName,
      applicantLastName,
      type,
      seen: true
    })
  }).catch((error) => console.log(error))
}

export const findSeenNotification = async (jobId) => {
  const user = localStorage.getItem('user')
  const data = await getNotification(user)
  if (!data.data().notificationNotSeen) return
  const notificationData = data.data().notificationNotSeen
  if (notificationData.length === 0) return

  for (let i = 0; i < notificationData.length; i++) {
    if (notificationData[i].jobId === jobId) {
      setNotificationSeen(notificationData[i], user, notificationData, jobId)
    }
  }
}

const setNotificationSeen = (
  foundNotificationData,
  user,
  notificationData,
  jobId
) => {
  updateNotificationDataSeen(
    { ...foundNotificationData, seen: true },
    user,
    notificationData,
    jobId
  )
}

const updateNotificationDataSeen = async (
  foundNotificationData,
  user,
  notificationData,
  jobId
) => {
  const notificationDoc = doc(db, 'notifications', user)
  await updateDoc(notificationDoc, {
    notificationSeen: arrayUnion({ ...foundNotificationData })
  })
    .then(() => getNotSelectedNotifications(notificationData, user, jobId))
    .catch((error) => console.log(error))
}

const getNotSelectedNotifications = (notificationData, user, jobId) => {
  const notSelectedNotificationsData = notificationData.filter(
    (notification) => notification.jobId !== jobId
  )
  updateNotificationDataNotSeen(notSelectedNotificationsData, user)
}

const updateNotificationDataNotSeen = async (
  notSelectedNotificationsData,
  user
) => {
  console.log(...notSelectedNotificationsData)
  const notificationDoc = doc(db, 'notifications', user)
  await deleteSeenNotifications(user)
    .then(() => {
      updateDoc(notificationDoc, {
        notificationNotSeen: arrayUnion(...notSelectedNotificationsData)
      })
    })
    .catch((error) => console.log(error))
}

export const deleteSeenNotifications = async (userId) => {
  const notificationRef = doc(db, 'notifications', userId)
  await updateDoc(notificationRef, { notificationNotSeen: deleteField() })
}
