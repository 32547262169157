import { createSlice } from '@reduxjs/toolkit'

export const myJobs = createSlice({
  name: 'myJobs',
  initialState: {
    jobs: {}
  },
  reducers: {
    setJobs: (state, action) => {
      state.jobs = action.payload
    }
  }
})

export const { setJobs } = myJobs.actions

export default myJobs.reducer
