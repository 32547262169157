import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage'

const storage = getStorage()

export const setStorage = async (file, user) => {
  const storageRef = ref(storage, `images/${user}`)
  return uploadBytesResumable(storageRef, file)
}

export const getImage = async (userId) => {
  const storageRef = ref(storage, `images/${userId}`)
  return await getDownloadURL(storageRef)
}
