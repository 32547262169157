import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AutoComplete from "../AutoComplete/AutoComplete";
import "./cardJobsEdit.css";
import { updateJob } from "../../firebase/firebase-jobs";
import DateAndTimePicker from "../DateAndTimePicker/DateAndTimePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 700,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicCard({ jobs, getJob, deleteJob, handleEditMode }) {
  const [open, setOpen] = useState(true);
  const [job, setJob] = useState({
    title: "",
    description: "",
    userAddress: {
      address: "",
      coordinates: {},
    },
    startDate: new Date(),
    endDate: new Date(),
  });

  const [currentJob, setCurrentJob] = useState({});
  const [error, setError] = useState({ errorMessage: "" });

  useEffect(() => {
    getCurrentJob();
  }, []);

  const getCurrentJob = async () => {
    await getJob(jobs.jobId).then((data) => setCurrentJob(data.data()));
  };

  const handleChange = (key, val) => {
    setJob({ ...job, [key]: val });
  };

  const handleChangeAddress = (key, val) => {
    setJob({ ...job, [key]: val });
  };

  return (
    <Modal
      open={open}
      onClose={handleEditMode}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="edit-card-job-container" sx={{ minWidth: 275 }}>
          <Button
            sx={{ marginBottom: 3 }}
            className="card-job-edit-btn"
            onClick={handleEditMode}
            size="large"
          >
            X
          </Button>
          <div className="card-job-edit-container-content">
            <TextField
              onChange={(e) => handleChange("title", e.target.value)}
              id="standard-basic"
              label="Title"
              variant="outlined"
            />
            <TextField
              onChange={(e) => handleChange("company", e.target.value)}
              id="standard-basic"
              label="company"
              variant="outlined"
            />
            <TextField
              onChange={(e) => handleChange("description", e.target.value)}
              multiline
              rows={4}
              id="standard-basic"
              label="Description"
              variant="outlined"
            />
            <DateAndTimePicker
              handleChangeAddress={handleChangeAddress}
              job={job}
            />

            {error?.errorMessage && <p>{error.errorMessage}</p>}
            <AutoComplete
              handleChange={handleChange}
              value={currentJob.userAddress}
            />

            <div className="card-job-edit-btn-container">
              <Button
                onClick={async () => {
                  await deleteJob(jobs.jobId);
                  await getJob().then(() => setOpen(false));
                }}
                variant="outlined"
                startIcon={<DeleteIcon />}
                color="error"
                size="large"
              >
                Delete
              </Button>
              <Button
                onClick={async () => {
                  if (!job.userAddress.coordinates.lat) {
                    return setError({
                      errorMessage: "Please enter a valid address.",
                    });
                  } else {
                    await updateJob(jobs.jobId, job);
                    await getJob()
                      .then(() => handleEditMode())
                      .then(() => setOpen(false));
                  }
                }}
                variant="contained"
                endIcon={<SaveIcon />}
                size="large"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
