import React, { useState, useEffect } from "react";
import NotificationItemAppliedFor from "./NotificationItemAppliedFor";
import "./notificationItem.css";

function NotificationItem({ notSeenNotification, seenNotification }) {
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    if (notSeenNotification?.length > 0) setNotificationsNotSeen();
    if (seenNotification?.length > 0) setNotificationsSeen();

    return () => {
      setNotification([]);
    };
  }, [notSeenNotification, seenNotification]);

  const setNotificationsNotSeen = () => {
    for (let i = notSeenNotification.length - 1; i >= 0; i--) {
      setNotification((job) => [
        ...job,
        { notification: notSeenNotification[i] },
      ]);
    }
  };

  const setNotificationsSeen = () => {
    for (let i = seenNotification.length - 1; i >= 0; i--) {
      setNotification((job) => [...job, { notification: seenNotification[i] }]);
    }
  };

  return (
    <div>
      {notification.length === 0 ? (
        <p>No notifications yet, check back later..</p>
      ) : (
        notification.map((item) => {
          return (
            <div className="notification-item-container">
              <NotificationItemAppliedFor notification={item.notification} />
            </div>
          );
        })
      )}
    </div>
  );
}

export default NotificationItem;
