import React, { useEffect, useState } from "react";
import {
  Popper,
  Paper,
  Grow,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Stack,
  Avatar,
  Skeleton,
  Typography,
} from "@mui/material";
import Popover from "../Popover/Popover";

import { useNavigate } from "react-router-dom";
import _ from "lodash";
import "./menulist.css";
import { useSelector } from "react-redux";

import { getMyProfileImage } from "../../helper/getProfileImage";
import SkeletonProfile from "../Skeleton/SkeletonProfile";

export default function MenuListComposition(props) {
  const { profileImage } = useSelector((state) => state.profileImage);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    getProfile();
  }, [profileImage]);

  let navigate = useNavigate();
  const user = localStorage.getItem("user");

  const anchorRef = React.useRef(null);

  useEffect(() => {
    getProfile();
  }, [image]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const getProfile = async () => {
    const imageUrl = await getMyProfileImage();
    setImage(imageUrl);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (!anchorRef.current) return;
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const popOverElements = () => {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            navigate(`/create`);
            handleClose(e);
          }}
        >
          Create jobs
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            handleClose(e);
            navigate("/manage");
          }}
        >
          Edit existing jobs
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            navigate("/my-jobs");
          }}
        >
          View applicants
        </MenuItem>
      </>
    );
  };

  return (
    <Stack
      sx={{ zIndex: "100" }}
      className="menu-container"
      direction="row"
      spacing={2}
    >
      <div className="menu-btn-div">
        {props.user && (
          <Button
            size="medium"
            className="menu-btn"
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <div className="menu-profile-bar">
              {!image ? (
                <>
                  <SkeletonProfile height={30} width={30} />
                  <Skeleton sx={{ marginLeft: 1, width: "100%" }} />
                </>
              ) : (
                <>
                  <Avatar
                    alt="profile"
                    sx={{ width: 30, height: 30 }}
                    src={image}
                  />
                  <Typography sx={{ margin: "auto" }}>
                    {props.user && _.capitalize(props.user.firstName)}
                  </Typography>
                </>
              )}
            </div>
          </Button>
        )}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) => {
                        navigate(`/profile/${user}`);
                        handleClose(e);
                      }}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        navigate("/");
                        handleClose(e);
                      }}
                    >
                      Listings
                    </MenuItem>
                    <Popover
                      elements={popOverElements}
                      buttonName={"My jobs"}
                    />
                    <Popover
                      elements={() => <MenuItem>Applied For</MenuItem>}
                      buttonName={"Applications"}
                    />
                    <MenuItem
                      onClick={(e) => {
                        props.signOut();
                        handleClose(e);
                      }}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
