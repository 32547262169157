import { db } from "../firebase/firebase-config";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  documentId,
  query,
  where,
  arrayUnion,
  updateDoc
} from "firebase/firestore";

const jobsCollectionRef = collection(db, "jobs");

export const getJobs = async (options) => {
  if (options) {
    const data = await getDocs(options);
    return data.docs;
  }
  const data = await getDocs(jobsCollectionRef);
  return data.docs;
};

export const deleteJob = async (id) => {
  const user = localStorage.getItem("user");
  const jobDoc = doc(db, "jobs", id);
  const userDoc = doc(db, "users", user);
  await deleteDoc(jobDoc);
  await setDoc(userDoc, { postedJobs: { [id]: "removed" } }, { merge: true });
};

export const getJob = async (id) => {
  const jobDoc = doc(db, "jobs", id);
  return await getDoc(jobDoc);
};

export const updateJob = async (id, job) => {
  const jobDoc = doc(db, "jobs", id);
  updateDoc(jobDoc, job);
};

export const updateJobApplicants = async (currentUserId, applicantUserData, jobId, applicantStatus) => {
  const {firstName, lastName} = applicantUserData
  const jobDoc = doc(db, "jobs", jobId);
  await setDoc(
    jobDoc,
    {
      applicants: arrayUnion({
        userId: currentUserId,
        firstName,
        lastName,
        status: applicantStatus,
        date: Date.now(),
      }),
    },
    { merge: true }
  ).catch((error) => console.log(error));
};

export const listenJobNotification = async (job) => {
  const data = [];
  Object.keys(job).forEach((id) => {
    const unsub = onSnapshot(doc(db, "jobs", id), (doc) => {
      const applicantData = doc.data();
      data.push(applicantData);
    });
  });
  return data;
};

export const getMyJobs = async (jobIds) => {
  const myJobs = [];
  const jobIdsToArray = Object.keys(jobIds);
  const snap = await getDocs(
    query(collection(db, "jobs"), where(documentId(), "in", jobIdsToArray))
  );
  const jobs = snap.docs.map((doc) => {
    const job = doc.data();
    myJobs.push({...job, jobId: doc.id});
  });
  return myJobs;
};
