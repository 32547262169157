import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import "./cardJobs.css";
import EditIcon from "@mui/icons-material/Edit";
import ApplyButton from "../Button/ApplyButton";
import { dateNow } from "../../utils/moment";
import { fontWeight } from "@mui/system";

export default function BasicCard(props) {
  const user = localStorage.getItem("user");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (
      props.selectedJob?.jobId === props.jobs.jobId &&
      props.options?.mobileView
    ) {
      setSelected(true);
    } else setSelected(false);
  }, [props]);

  const handleApplicants = () => {

    if (!props.jobs.applicants.length) {
      return "No applicants"
    }else if (props.jobs.applicants.length === 1) {
      return "1 applicant"
    }else {
      return `${props.jobs.applicants.length} applicants`
    }
  }
  return (
    <Card
      sx={{
        maxWidth: "450px",
        height: selected ? "450px" : "250px",
      }}
      className={`${props.options?.pointer ? "pointer" : ""}`}
    >
      <CardContent
        sx={{ height: selected ? "450px" : "250px", color: "rgb(33,31,32)" }}
      >
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: 0,
          }}
        >
          <Typography sx={{ fontSize: 12 }}>
            {dateNow(props.jobs.createdAt)}
          </Typography>
          <Typography sx={{color: "#057642", fontSize: 12, fontWeight: 500}} variant="body1">
            {handleApplicants()}
          </Typography>
          {props.jobs.userId === user && props.button === "Edit" && (
            <Button onClick={() => props.handleEditMode(props)} size="small">
              <EditIcon />
            </Button>
          )}
        </CardActions>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18, color: "rgb(45, 45, 45)" }}
          component="div"
        >
          {props.jobs.title}
        </Typography>
        <Typography>{props.jobs.company}</Typography>
        <Typography sx={{ fontSize: 13 }} gutterBottom>
          {props.jobs.userAddress.address}
        </Typography>
        <Typography
          sx={{ mt: 2, height: selected ? "220px" : "75px" }}
          component="div"
        >
          {!selected
            ? _.truncate(props.jobs.description, { length: 100 })
            : _.truncate(props.jobs.description, { length: 401 })}
        </Typography>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          {selected &&
            props.options?.mobileView &&
            props.selectedJob.userId !== user && (
              <ApplyButton
                selectedJob={props.selectedJob}
                applyJob={props.applyJob}
              />
            )}
        </CardActions>
      </CardContent>
    </Card>
  );
}
