import { useState, useEffect } from 'react'
import { Typography, Avatar, Divider, Grid, Button, Box } from '@mui/material/'
import { dateNow } from '../../utils/moment'
import { useNavigate } from 'react-router-dom'
import { updateJob, getMyJobs } from '../../firebase/firebase-jobs'
import { getUser } from '../../firebase/firebase-users'
import { useDispatch } from 'react-redux'
import { setJobs } from '../../redux/myJobs'

export default function MiddleDividers({ job, applicant, i, images }) {
  const dispatch = useDispatch()
  const userId = localStorage.getItem('user')
  const [userAccepted, setUserAccepted] = useState(false)
  const [slotFull, setSlotFull] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    isApplicantAccepted()
    isSlotsFull()
  })

  const isApplicantAccepted = () => {
    job.assignedTo.forEach((user) => {
      if (applicant.userId === user.userId) {
        setUserAccepted(true)
      }
    })
  }

  const isSlotsFull = () => {
    if (job.slots === job.slotsFilled) {
      setSlotFull(true)
    } else setSlotFull(false)
  }

  const handleJobAccept = async () => {
    const allOtherApplicants = job.applicants.filter((user) => user.userId !== applicant.userId )
    
    await updateJob(job.jobId, {
      ...job,
      slotsFilled: job.slotsFilled + 1,
      applicants: [{...applicant, status: 'selected'}, ...allOtherApplicants]
    })

    // what does this do? document it
    const userData = await getUser(userId)
    const myJobs = await getMyJobs(userData.data().postedJobs)
    dispatch(setJobs(myJobs))
    setUserAccepted(true)
  }

  const handleUndo = async () => {
    const allOtherApplicants = job.applicants.filter((user) => user.userId !== applicant.userId )

    console.log(allOtherApplicants)
    await updateJob(job.jobId, {
      ...job,
      slotsFilled: job.slotsFilled - 1,
      applicants: [{...applicant, status: 'pending'}, ...allOtherApplicants]
    })
    const userData = await getUser(userId)
    const myJobs = await getMyJobs(userData.data().postedJobs)
    dispatch(setJobs(myJobs))
    setUserAccepted(false)
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
      <Box sx={{ my: 3 }}>
        <Grid container alignItems='center'>
          <Grid
            onClick={() => navigate(`/profile/${applicant.userId}`)}
            item
            xs={2}
          >
            {images && <Avatar alt='profile' src={images[i]}></Avatar>}
          </Grid>
          <Grid item xs={2}>
            <Typography gutterBottom variant='body1' component='div'>
              {applicant.firstName}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography gutterBottom variant='body1' component='div'>
              {applicant.lastName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              gutterBottom
              color='text.secondary'
              variant='body2'
              component='div'
            >
              {dateNow(applicant.date)}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ my: 3 }}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            {userAccepted && (
              <Button
                onClick={() => handleUndo()}
                size='small'
                variant='contained'
              >
                Undo
              </Button>
            )}
            {!slotFull && !userAccepted && (
              <>
                <Button
                  onClick={() => handleJobAccept()}
                  sx={{ mx: 1 }}
                  size='small'
                  variant='contained'
                >
                  Accept
                </Button>
                <Button size='small' variant='outlined' color='error'>
                  Decline
                </Button>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      <Divider variant='inset' />
    </Box>
  )
}
