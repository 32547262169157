import { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText
} from '@mui/material/'
import './viewApplicantsDialog.scss'
import { getProfileImage } from '../../helper/getProfileImage'
import ApplicantContent from '../ApplicantContent/ApplicantContent'
import NotFound from '../NotFound/NotFound'

export default function ViewApplicantsDialog({ job, applicants, buttonName }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [images, setImages] = useState()

  useEffect(() => {
    getProfile(applicants)
  }, [])

  const getProfile = async (applicants) => {
    const imagesLoaded = await Promise.all(
      applicants.map(
        async (applicant) => await getProfileImage(applicant.userId)
      )
    )
    setImages(imagesLoaded)
  }

  return (
    <div>
      <Button onClick={handleOpen}>{buttonName}</Button>
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          {applicants.length === 0 ? (
            <NotFound
              message={{
                header: 'No applicants yet...',
                description: 'Check back later!',
                disableBtn: true
              }}
            ></NotFound>
          ) : (
            applicants.map((applicant, i) => (
              <ApplicantContent
                job={job}
                applicant={applicant}
                index={i}
                images={images}
              ></ApplicantContent>
            ))
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
