import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUser } from "../../firebase/firebase-users";
import ProgressBar from "../../components/Progressbar/ProgressBar";
import "./myJobs.css";
import { getMyJobs } from "../../firebase/firebase-jobs";
import Accordion from "../../components/Accordion/Accordion";
import NotFound from "../../components/NotFound/NotFound";
import { useSelector, useDispatch } from 'react-redux'
import { setJobs } from "../../redux/myJobs"

function MyJobs() {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(true);
  const [notFound, setNotFound] = useState(false)
  const currentUser = localStorage.getItem("user");
  const { jobs } = useSelector((state) => state.myJobs)
  console.log(jobs)
  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = async () => {
    const userData = await getUser(currentUser);
    if (!userData.data().postedJobs) {
      setNotFound(true)
      setOpen(false)
    }else {
      const myJobs = await getMyJobs(userData.data().postedJobs);
      dispatch(setJobs(myJobs))
      setOpen(false)
    }

  };

  return (
    <div className="my-jobs-container">
      <ProgressBar open={open} />
      {notFound &&  <NotFound message={{header: "No jobs found", description: "Click here to create one"}}></NotFound>
        }
      {!open && !notFound && (
        <div className="my-jobs-inner">
          <Typography variant="h4" sx={{ my: 2 }}>
            My Jobs
          </Typography>

            <Typography variant="h5">Active listings: {jobs.length}</Typography>
            {jobs.map((job) => (
              <Accordion job={job} />
            ))}
          </div>

      )}
    </div>
  );
}

export default MyJobs;
