import React from "react";
import "./notFound.css";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function NotFound({ message }) {
  const { header, description, disableBtn = false} = message;
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <Typography variant="h4" className="not-found-header">
        {header}
      </Typography>
      <Typography className="not-found-description">{description}</Typography>
      {!disableBtn && (
        <Button variant="outlined" onClick={() => navigate("/create")}>
          Create Job
        </Button>
      )}
    </div>
  );
}

export default NotFound;
