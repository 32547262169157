import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { getUser } from "../../firebase/firebase-users";
import RatingStars from "../RatingStars/RatingStars";
import ProgressBar from "../Progressbar/ProgressBar";
import ProfileImage from "../ProfileImage/ProfileImage";
import {
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material/";
import _ from "lodash";
import { Edit } from "@mui/icons-material/";
import "./cardProfile.scss";

export default function CardProfile({ params }) {
  const userId = localStorage.getItem("user");
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(true);

  useEffect(() => {
    getCurrentUserProfile();
  }, [params]);

  const getCurrentUserProfile = async () => {
    let currentUserProfile = await getUser(params.userId);
    const data = currentUserProfile.data();
    setUser({ userId: currentUserProfile.id, ...data });
    setOpen(false);
  };

  const showEditButton = () => {
    if (user.userId === userId) {
      return (
        <Button onClick={() => navigate("/user-information")} size="small">
          <Edit />
        </Button>
      );
    }
  };

  return (
    <>
      <ProgressBar open={open} />
      {!open && (
        <Card className="card-profile-container">
          <CardContent className="card-profile-content">
            <ProfileImage params={params} />

            <CardActions>
              <Typography variant="h4" component="div">
                {`${_.startCase(_.toLower(user.firstName))}  
               ${_.startCase(_.toLower(user.lastName))}
             `}
              </Typography>
              {showEditButton()}
            </CardActions>

            <Typography
              sx={{ fontSize: 14, mt: 2 }}
              color="text.secondary"
              gutterBottom
            >
              {user.userAddress.address}
            </Typography>
            <Typography
              sx={{ fontSize: 14, mt: 2 }}
              color="text.secondary"
              gutterBottom
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum non risus eu nunc pellentesque convallis. Vivamus
              facilisis, orci ac auctor feugiat, orci metus ullamcorper dolor, a
              dapibus arcu dui non nunc. Sed porttitor libero et tortor ornare,
              a dapibus neque pellentesque. Vivamus nec nunc maximus, facilisis
              quam eget, pellentesque lorem. Etiam tempor turpis vitae diam
              cursus, nec scelerisque urna viverra. Phasellus tincidunt egestas
              ligula, nec mollis ante tempus non. Pellentesque eget orci dui.
            </Typography>
            {/* <Typography
              sx={{ fontSize: 14, mt: 2 }}
              color="text.secondary"
              gutterBottom
            >
              {user.userAddress.coordinates.lat
                ? `${user.userAddress.coordinates.lat}, ${user.userAddress.coordinates.lng}`
                : "Address not set"}
            </Typography> */}

            {/* <Typography sx={{ mt: 2 }} variant="body2">
              {user.phone ? user.phone : "Phone not set"}
            </Typography> */}
          </CardContent>
        </Card>
      )}
    </>
  );
}
