import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import { logOut } from "../../firebase/firebase-auth";
import { getUser, updateUser } from "../../firebase/firebase-users";
import MenuList from "../MenuList/MenuList";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchBar from "../SearchBar/SearchBar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import NotificationWindow from "../NotificationWindow/NotificationWindows";

function Navbar() {
  let navigate = useNavigate();
  const user = localStorage.getItem("user");

  const { isLoggedIn } = useSelector((state) => state.isLoggedIn);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [notify, setNotify] = useState(0);
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  useEffect(() => {
    fetchUserInformation();
  }, [isLoggedIn]);

  const fetchUserInformation = async () => {
    const currentUser = await getUser(user);
    setCurrentUser(currentUser.data());
  };

  const signOut = async () => {
    updateUser({ isLoggedIn: false }, user);
    logOut();
    localStorage.clear();
    navigate("/");
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSearchBar = () => {
    setSearchBarOpen(true);
  };

  const handleClickAwaySearch = () => {
    setSearchBarOpen(false);
  };

  const handleClickAwayNotification = () => {
    setOpen(false);
  };

  return (
    <nav>
      <Typography
        onClick={() => navigate("/")}
        variant="h4"
        className="nav-logo"
      >
        WERKLIFY
      </Typography>

      {currentUser && (
        <div className="nav-container">
          <ClickAwayListener onClickAway={handleClickAwaySearch}>
            <div className="search-button">
              {searchBarOpen ? (
                <SearchBar />
              ) : (
                <IconButton
                  sizeSmall
                  aria-label="search users"
                  aria-haspopup="true"
                  onClick={() => handleSearchBar()}
                  color="inherit"
                >
                  <SearchIcon />
                </IconButton>
              )}
            </div>
          </ClickAwayListener>

          <IconButton
            sizeSmall
            aria-label="search users"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            <span className="notification-counter">
              <NotificationsIcon fontSize="medium" />
              {notify !== 0 && notify}
            </span>
          </IconButton>

          {open && (
            <NotificationWindow
              setNotify={setNotify}
              handleClickAwayNotification={handleClickAwayNotification}
            />
          )}

          <MenuList signOut={signOut} user={currentUser} />
        </div>
      )}
    </nav>
  );
}

export default Navbar;
