import React from "react";
import { useParams } from "react-router-dom";
import CardProfile from "../../components/Card/CardProfile";
import "./profile.css";

function Profile() {
  let params = useParams();

  return (
    <div className="profile-container">
          <CardProfile params={params} />
    </div>
  );
}

export default Profile;
