import { createSlice } from '@reduxjs/toolkit'

export const profileImage = createSlice({
  name: 'profileImage',
  initialState: {
    profileImage: ''
  },
  reducers: {
    profileUpdated: (state, action) => {
      state.profileImage = action.payload
    }
  }
})

export const { profileUpdated } = profileImage.actions

export default profileImage.reducer
