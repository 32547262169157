import React, { useState, useEffect } from "react";
import CardJobsEdit from "../../components/Modal/CardJobsEdit";
import "./manageJobs.css";
import { getJobs, deleteJob } from "../../firebase/firebase-jobs";
import EditJobs from "../../components/Card/EditJobs";
import ProgressBar from "../../components/Progressbar/ProgressBar";
import { Typography } from "@mui/material";
import NotFound from "../../components/NotFound/NotFound";
import { db } from "../../firebase/firebase-config";
import { query, collection, orderBy } from "firebase/firestore";

function ManageJobs() {
  const [open, setOpen] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [myJobs, setMyJobs] = useState([]);
  const [currentUserId, setCurrentUserId] = useState();
  const [editmode, setEditMode] = useState({ active: false, id: "" });

  useEffect(() => {
    const userId = localStorage.getItem("user");
    setCurrentUserId(userId);
  }, []);

  useEffect(() => {
    getJob();
  }, []);

  useEffect(() => {
    setMyCurrentJobs();
  }, [jobs]);

  const setMyCurrentJobs = () => {
    setMyJobs(
      jobs.filter((job) => job.userId === currentUserId && { ...myJobs, job })
    );
  };

  const getJob = async () => {
    const queryOptions = query(
      collection(db, "jobs"),
      orderBy("createdAt", "desc")
    );

    await getJobs(queryOptions)
      .then((data) => {
        setJobs(data.map((doc) => ({ ...doc.data(), jobId: doc.id })));
      })
      .then(() => setOpen(false));
  };

  const handleEditMode = async (id) => {
    setEditMode({ ...editmode, active: !editmode.active, id: id });
    if (id !== editmode.id) setEditMode({ ...editmode, active: true, id: id });
  };

  return (
    <div className="manage-container">
      <ProgressBar open={open} />
      {!open && (
        <div>
          {!myJobs.length ? (
            <NotFound
              message={{
                header: "Oops, you have no jobs!",
                description: "Click here to create one",
              }}
            />
          ) : (
            <Typography variant="h4" className="manage-header">
              My listings.
            </Typography>
          )}
          {jobs.map(
            (job) =>
              currentUserId === job.userId && (
                <div className="manage-jobs-container">
                  <EditJobs
                    key={job.userId}
                    jobs={job}
                    handleEditMode={() => {
                      handleEditMode(job.jobId);
                    }}
                    button={"Edit"}
                  />

                  {editmode.active && job.jobId === editmode.id && (
                    <CardJobsEdit
                      className="manage-jobs-edit-container"
                      jobs={job}
                      getJob={getJob}
                      deleteJob={deleteJob}
                      handleEditMode={() => handleEditMode(job.jobId)}
                    />
                  )}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
}

export default ManageJobs;
