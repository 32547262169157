import React from "react";
import { IconButton } from "@mui/material/";
import { setStorage } from "../../firebase/firebase-storage";
import { useDispatch } from "react-redux";

import { profileUpdated } from "../../redux/profileImage";
import { PhotoCamera } from "@mui/icons-material/";

export default function ImageUploadButton() {
  const dispatch = useDispatch();

  const handleImageAsFile = async (e) => {
    const selectedFile = e.target.files[0];
    const user = localStorage.getItem("user");
    await setStorage(selectedFile, user).then(() =>
      dispatch(profileUpdated(selectedFile.name))
    );
  };

  return (
    <IconButton
      type="file"
      accept="image/*"
      color="primary"
      aria-label="upload picture"
      component="label"
      onChange={handleImageAsFile}
    >
      <input hidden accept="image/*" type="file" />
      <PhotoCamera />
    </IconButton>
  );
}
