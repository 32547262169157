import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './notificationItemAppliedFor.css'
import { findSeenNotification } from '../../firebase/firebase-notifications'

function NotificationItemAppliedFor(props) {
  const navigate = useNavigate()

  return (
    <div
      className='notification-appliedfor-container'
      onClick={() => navigate(`/profile/${props.notification.applicantId}`)}
      style={{ height: '50px', width: '100%' }}
    >
      {!props.notification.seen && (
        <div
          className='notification-not-seen'
          onClick={() => findSeenNotification(props.notification.jobId)}
        >
          <p>
            <b>{props.notification.applicantFirstName}</b>{' '}
            <b>{props.notification.applicantLastName}</b> has applied for{' '}
            <b>{props.notification.jobTitle}</b>
          </p>
        </div>
      )}
      {props.notification.seen && (
        <div className='notification-seen'>
          <p>
            <b>{props.notification.applicantFirstName}</b>{' '}
            <b>{props.notification.applicantLastName}</b> has applied for{' '}
            <b>{props.notification.jobTitle}</b>
          </p>
        </div>
      )}
    </div>
  )
}

export default NotificationItemAppliedFor
