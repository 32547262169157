import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import "./cardJobs.css";
import EditIcon from "@mui/icons-material/Edit";
import { dateNow } from "../../utils/moment";

export default function EditJobs({selectedJob, jobs, options, handleEditMode}) {
  const user = localStorage.getItem("user");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (
      selectedJob?.jobId === jobs.jobId &&
      options?.mobileView
    ) {
      setSelected(true);
    } else setSelected(false);
  }, [selectedJob]);

  return (
    <Card
      sx={{
        maxWidth: "450px",
        height: selected ? "450px" : "250px",
      }}
      className={`${options?.pointer ? "pointer" : ""}`}
    >
      <CardContent
        sx={{ height: selected ? "450px" : "250px", color: "rgb(33,31,32)" }}
      >
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: 0,
          }}
        >
          <Typography sx={{ fontSize: 12 }}>
            {dateNow(jobs.createdAt)}
          </Typography>
          <Button onClick={() => handleEditMode(jobs)} size="small">
            <EditIcon />
          </Button>
        </CardActions>
        <Typography
          sx={{ fontWeight: 700, fontSize: 18, color: "rgb(45, 45, 45)" }}
          component="div"
        >
          {jobs.title}
        </Typography>
        <Typography>{jobs.company}</Typography>
        <Typography sx={{ fontSize: 13 }} gutterBottom>
          {jobs.userAddress.address}
        </Typography>
        <Typography
          sx={{ mt: 2, height: selected ? "220px" : "75px" }}
          component="div"
        >
          {!selected
            ? _.truncate(jobs.description, { length: 100 })
            : _.truncate(jobs.description, { length: 401 })}
        </Typography>
      </CardContent>
    </Card>
  );
}
