import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Listings from "./pages/listings/Listings";
import CreateJob from "./pages/createJob/CreateJob";
import Login from "./pages/login/Login";
import ManageJobs from "./pages/manageJobs/ManageJobs";
import Profile from "./pages/profile/Profile";
import UserInformation from "./pages/userInformation/UserInformation";
import MyJobs from "./pages/MyJobs/MyJobs";
import Copyright from "./components/Footer/Copyright";
import { setUserLoggedIn } from "./redux/isLoggedIn";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebase-config";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  // force rerender when isLoggedIn changes
  useSelector((state) => state.isLoggedIn.isLoggedIn);

  // firebase authentication listener
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      localStorage.setItem("user", currentUser.uid);
      dispatch(setUserLoggedIn(true));
    } else {
      localStorage.removeItem("user");
      dispatch(setUserLoggedIn(false));
    }
  });

  const user = localStorage.getItem("user");

  return (
    <Router>
      <Navbar />
      <Routes>
        {!user ? (
          <Route path="*" element={<Login />}></Route>
        ) : (
          <>
            <Route exact path="/" element={<Listings />} />
            <Route exact path="/create" element={<CreateJob />} />
            <Route
              exact
              path="/user-information"
              element={<UserInformation />}
            />
            <Route exact path="/manage" element={<ManageJobs />} />
            <Route exact path={"/profile/:userId"} element={<Profile />} />
            <Route exact path={"/my-jobs"} element={<MyJobs />} />
          </>
        )}
      </Routes>
      <Copyright />
    </Router>
  );
}

export default App;
