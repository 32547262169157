import React, { useState } from "react";
import "./userInformation.css";
import { updateUser } from "../../firebase/firebase-users";
import AutoComplete from "../../components/AutoComplete/AutoComplete";
import { Card, Typography, Button } from "@mui/material/";
import PhoneInput from "../../components/TextField/PhoneInput";
import SnackBar from "../../components/SnackBar/SnackBar";

function UserInformation() {
  const userId = localStorage.getItem("user");
  const [userInfo, setUserInfo] = useState({
    userAddress: {
      address: "",
      coordinates: {},
    },
    phone: "",
  });
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({});

  const updateUserInfo = () => {
    if (!userInfo.userAddress.coordinates.lat) {
      setLoading(false);
      return setAlert({
        open: true,
        message: "Please enter a valid address",
        type: "error",
      });
    }

    updateUser(userInfo, userId)
      .then(() => setLoading(false))
      .then(() =>
        setAlert({
          open: true,
          message: "Updated",
          type: "success",
        })
      )
      .finally(() =>
        setUserInfo({
          userAddress: { address: "", coordinates: {}, phone: "" },
        })
      );
  };

  const handleChange = (key, val) => {
    setAlert({
      open: false,
    });
    setUserInfo({ ...userInfo, [key]: val });
  };
  return (
    <div className="user-info-container">
      <Typography sx={{ color: "rgb(45, 45, 45)", mt: 5, mb: 2 }} variant="h4">
        User Profile
      </Typography>

      <Card
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          updateUserInfo();
        }}
        component="form"
        sx={{ p: 3 }}
        autoComplete="off"
        className="user-info-form-container"
      >
        <AutoComplete handleChange={handleChange} />

        <PhoneInput handleChange={handleChange} />

        <div className="user-info-btn-upload">
          <Typography sx={{ mt: 5 }} variant="h5" component="div"></Typography>
        </div>
        <div className="user-info-btn-container">
          <Button
            className="user-info-btn"
            type="submit"
            variant="contained"
            size="medium"
          >
            {!loading ? "Submit" : "Uploading..."}
          </Button>
        </div>
      </Card>
      <SnackBar alert={alert} />
    </div>
  );
}
export default UserInformation;
