import { createSlice } from "@reduxjs/toolkit";

export const isLoggedIn = createSlice({
  name: "isLoggedIn",
  initialState: {
    isLoggedIn: false,
    auth: null,
  },
  reducers: {
    setUserLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
  },
});

export const { setUserLoggedIn } = isLoggedIn.actions;

export default isLoggedIn.reducer;
