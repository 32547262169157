import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import './searchBar.css'
import { getUserByName } from '../../firebase/firebase-users'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
function SearchBar() {
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState([])
  const [active, setActive] = useState('')
  const [dataExists, setDataExists] = useState(true)

  useEffect(() => {
    checkIfDataExists()
  }, [searchResults])

  const handleSearch = (e) => {
    if (!e.target.value) return setActive('')
    setActive(e.target.value)

    getUserSearchResults(e)
  }

  const getUserSearchResults = async (e) => {
    await getUserByName(e.target.value).then((data) => {
      setSearchResults([])
      data.forEach((doc) => {
        setSearchResults((oldArray) => [
          ...oldArray,
          { ...doc.data(), userId: doc.id }
        ])
      })
    })
  }

  const checkIfDataExists = () => {
    searchResults.length > 0 ? setDataExists(true) : setDataExists(false)
  }

  const navigateToProfile = (result) => {
    setActive('')
    navigate(`profile/${result.userId}`)
  }

  return (
    <div className='search-bar-container'>
      <TextField
        size='small'
        autoComplete='off'
        id='outlined-search'
        onChange={(e) => {
          setSearchResults([])
          handleSearch(e)
        }}
        label='Search users'
        type='search'
      />
      {active.length > 0 && (
        <div className='search-bar-results'>
          {!dataExists ? (
            <p>No matches found.</p>
          ) : (
            searchResults.map((result) => (
              <p
                className='search-bar-item'
                onClick={() => navigateToProfile(result)}
              >
                {result.fullName}
              </p>
            ))
          )}
        </div>
      )}
    </div>
  )
}

export default SearchBar
