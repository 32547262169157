import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import '../Card/cardJobs.css'

function ApplyButton({applyJob, selectedJob}) {
  const user = localStorage.getItem('user')
  const [disabled, setDisabled] = useState()


  useEffect(() => {
    checkApplied()
  }, [selectedJob])

  const checkApplied = () => {
    setDisabled(false)
    selectedJob.applicants.forEach(({userId}) => {
      if (userId === user) setDisabled(true)
    })
  }



  return (
    <Button
      className={('card-apply-btn', disabled && 'card-apply-btn-disabled')}
      disabled={disabled}
      onClick={() => {
        applyJob(user, { ...selectedJob }, 'pending', 'appliedJobs')
      }}
      size='small'
    >
      {disabled ? 'Applied' : 'Apply'}
    </Button>
  )
}

export default ApplyButton
