import { getImage } from "../firebase/firebase-storage";

export const getMyProfileImage = async () => {
  const myUser = localStorage.getItem("user");
  const imageUrl = await getImage(myUser).catch((error) => {
    return getImage("/default.png");
  });
  return imageUrl;
};

export const getProfileImage = async (userId) => {
  const imageUrl = await getImage(userId).catch((error) => {
    // console.error(error)
    return getImage("/default.png");
  });
  return imageUrl;
};
