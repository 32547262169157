import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ApplyButton from "../Button/ApplyButton";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import "./cardJobs.css";

export default function BasicCard({selectedJob, applyJob}) {

  const user = localStorage.getItem("user");

  const {title, userAddress, userId, company, description} = selectedJob;

  return (
    <Card sx={{ width: 500, height: "100vh" }}>
      <CardContent sx={{ color: "rgb(33,31,32)", width: 500, height: 900 }}>
        <Typography
          sx={{ height: 25, fontWeight: 700, fontSize: 18, color: "rgb(45, 45, 45)" }}
          component="div"
        >
          {title}
        </Typography>
        <Typography sx={{height: 20}}>{company}</Typography>
        <Typography sx={{ fontSize: 13, height: 20 }} gutterBottom>
          {userAddress.address}
        </Typography>
        <Typography sx={{ mt: 2, height: 750 }} component="div">
          {description}
        </Typography>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end"}}>
          {userId !== user && (
            <ApplyButton
              selectedJob={selectedJob}
              applyJob={applyJob}
            />
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}
