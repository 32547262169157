import { db } from './firebase-config'
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  getDoc,
  query,
  orderBy,
  startAt,
  endAt
} from 'firebase/firestore'

const usersCollectionRef = collection(db, 'users')

export const getUsers = async () => {
  const data = await getDocs(usersCollectionRef)
  return data.docs
}

export const getUser = async (id) => {
  try {
    const docRef = doc(db, 'users', id)
    return await getDoc(docRef)
  } catch (error) {
    console.log(error)
  }
}

export const getUserByName = async (name) => {
  try {
    console.log(name)
    const user = query(
      usersCollectionRef,
      orderBy('fullName'),
      startAt(name),
      endAt(name + '\uf8ff')
    )

    return await getDocs(user)
  } catch (error) {
    console.log(error)
  }
}

export const createUser = async (userId, userInfo) => {
  await setDoc(doc(db, 'users', userId), userInfo)
}

export const updateUser = async (value, id) => {
  const userDoc = doc(db, 'users', id)
  await updateDoc(userDoc, value).catch((error) => console.log(error))
}

export const updateUserJobs = async (jobId, userId, value, key) => {
  const userDoc = doc(db, 'users', userId)
  await setDoc(userDoc, { [key]: { [jobId]: value } }, { merge: true }).catch(
    (error) => console.log(error)
  )
}
