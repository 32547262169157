import React, { useState, useEffect } from 'react'
import { db } from '../../firebase/firebase-config'
import { collection, addDoc } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../firebase/firebase-config'
import SaveIcon from '@mui/icons-material/Save'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import SnackBar from '../../components/SnackBar/SnackBar'
import './createJob.css'
import AutoComplete from '../../components/AutoComplete/AutoComplete'
import { Typography } from '@mui/material'
import DateAndTimePicker from '../../components/DateAndTimePicker/DateAndTimePicker'
import { updateUserJobs } from '../../firebase/firebase-users'
import Card from '@mui/material/Card'

function InputForm() {
  const [job, setJob] = useState({
    userId: '',
    title: '',
    company: '',
    description: '',
    userAddress: {
      address: '',
      coordinates: {}
    },
    startDate: new Date(),
    endDate: new Date(),
    createdAt: Date.now(),
    showJob: true, 
    applicants: [],
    slots: 1,
    slotsFilled: 0,
    status: 'pending',
    assignedTo: []
  })

  const [alert, setAlert] = useState({})

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setJob({
          ...job,
          userId: currentUser.uid
        })
      } else {
        console.log('no user found')
      }
    })
  }, [])

  const submitJob = async (e) => {
    e.preventDefault()
    if (!job.userId) return
    if (!job.userAddress.coordinates.lat)
      return setAlert({
        open: true,
        message: 'Please enter a valid address',
        type: 'error'
      })

    const createdJob = await addDoc(collection(db, 'jobs'), job)
    await updateUserJobs(createdJob.id, job.userId, 'pending', 'postedJobs')
    setJob({
      ...job,
      title: '',
      company: '',
      description: '',
      userAddress: {
        address: '',
        coordinates: {}
      },
      startDate: new Date(),
      endDate: new Date(),
      createdAt: Date.now(),
      slots: 1,
    })
    setAlert({ open: true, message: 'Job Posted.', type: 'success' })
  }

  const handleChange = (key, val) => {
    setAlert({ open: false, message: 'Job Posted.', type: 'success' })
    setJob({ ...job, [key]: val })
  }
  const handleChangeAddress = (key, val) => {
    setJob({ ...job, [key]: val })
  }

  return (
    <div className='create-job-container'>
      <Typography variant='h4' className='create-job-logo'>
        Create Job.
      </Typography>
      {!job.userId ? (
        <p>You must log in to create a job.</p>
      ) : (
        <Card
          component='form'
          autoComplete='off'
          className='create-job-form-container'
          onSubmit={submitJob}
        >
          <div className='create-job-edit-container-content'>
            <TextField
              inputProps={{
                maxLength: 50
              }}
              onChange={(e) => {
                handleChange('title', e.target.value)
              }}
              id='standard-basic'
              label='Title'
              variant='outlined'
              value={job.title}
            />
            <TextField
              onChange={(e) => {
                handleChange('company', e.target.value)
              }}
              inputProps={{
                maxLength: 50
              }}
              id='standard-basic'
              label='Company'
              variant='outlined'
              value={job.company}
            />
            <TextField
              onChange={(e) => {
                handleChange('slots', e.target.value)
              }}
              inputProps={{
                maxLength: 50
              }}
              id='standard-basic'
              label='Slots'
              variant='outlined'
              value={job.slots}
            />
            <TextField
              onChange={(e) => {
                handleChange('description', e.target.value)
              }}
              multiline
              rows={4}
              inputProps={{
                maxLength: 400
              }}
              id='standard-basic'
              label='Description'
              variant='outlined'
              value={job.description}
            />

            <DateAndTimePicker
              handleChangeAddress={handleChangeAddress}
              job={job}
            />
            <AutoComplete
              handleChange={handleChangeAddress}
              address={job.userAddress.address}
              alert={alert}
            />

            <div className='create-job-edit-btn-container'>
              <Button
                onClick={() =>
                  setJob({
                    ...job,
                    title: '',
                    company: '',
                    description: '',
                    userAddress: {
                      address: '',
                      coordinates: {}
                    }
                  })
                }
                variant='outlined'
                color='error'
                size='large'
              >
                Clear
              </Button>
              <Button
                type='submit'
                variant='contained'
                endIcon={<SaveIcon />}
                size='large'
              >
                Save
              </Button>
            </div>
          </div>
        </Card>
      )}
      <SnackBar alert={alert} />
    </div>
  )
}

export default InputForm
