import { Skeleton, Avatar } from "@mui/material";

function SkeletonProfile({ height, width }) {
  return (
    <Skeleton variant="circular">
      <Avatar sx={{ width, height }} />
    </Skeleton>
  );
}

export default SkeletonProfile;
